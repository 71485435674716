








import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

import UiBanner from '@/components/ui/UiBanner.vue';
import UiButton from '@/components/ui/UiButton.vue';

@Component({
  components: {
    UiButton,
    UiBanner,
  },
})
export default class BetaProvideFeedback extends Vue {
  @Prop({ default: '#' })
  public readonly link!: string;

  public show = true;
}
